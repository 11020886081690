export const GOOGLE_CLIENT_ID = import.meta.env.VITE_GOOGLE_CLIENT_ID
export const MIXPANEL_TOKEN = import.meta.env.VITE_MIXPANEL_TOKEN
export const CHROME_EXTENSION_ID = import.meta.env.VITE_CHROME_EXTENSION_ID
export const CHROME_STORE_URL = import.meta.env.VITE_CHROME_STORE_URL
export const CAMPAIGN_WEBHOOK_URL = import.meta.env.VITE_CAMPAIGN_WEBHOOK_URL
export const GOOGLE_WORKSPACE_AUTH_GUIDE_URL = import.meta.env
  .VITE_GOOGLE_WORKSPACE_AUTH_GUIDE_URL
export const HOTJAR_ID = import.meta.env.VITE_HOTJAR_ID
export const HOTJAR_SNIPPET_VERSION = import.meta.env
  .VITE_HOTJAR_SNIPPET_VERSION
export const HUBSPOT_CLIENT_ID = import.meta.env.VITE_HUBSPOT_CLIENT_ID
export const HUBSPOT_REDIRECT_URI = import.meta.env.VITE_HUBSPOT_REDIRECT_URI
export const JIRA_INTEGRATION_GUIDE_URL = import.meta.env
  .VITE_JIRA_INTEGRATION_GUIDE_URL
export const MICROSOFT_CLIENT_ID = import.meta.env.VITE_MICROSOFT_CLIENT_ID
export const TRELLO_API_KEY = import.meta.env.VITE_TRELLO_API_KEY
export const VITE_PIPEFY_TUTORIAL_URL = import.meta.env.VITE_PIPEFY_TUTORIAL_URL
export const INSTALL_WATCHER_URL = import.meta.env.VITE_INSTALL_WATCHER_URL
export const DESKBEE_TUTORIAL_URL = import.meta.env.VITE_DESKBEE_TUTORIAL_URL
